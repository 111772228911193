import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// import { useInput } from "./hooks/input-hook";
import useInput from "@rooks/use-input";
import storage from "../../../services/storage";
import StandardButton from "../../buttons/StandardButton";
import Input from "./Input";
import GridContainer from "../../themes/GridContainer";
import DeleteAlbumButton from "./DeleteAlbumButton";
import JCardImageUploader from "../../file_mgmt/JCardImageUploader";
export default function EditCassetteMetadataView({ album }) {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  // now editing: {this.props.album.name}
  const [isEditingAlbumName, setEditingAlbumName] = useState(false);
  const [name, setAlbumName] = useState(album.name);

  const [input, setInput] = useState("");

  const onChange = (e) => {
    setInput(e.currentTarget.value);
  };

  // useEffect(() => {
  //   // Update the document title using the browser API
  // }, [name]);

  function getEditButton() {
    if (!isEditingAlbumName) {
      return (
        <div className="grid">
          <div style={{ marginTop: 15 }}>{name}</div>
          <StandardButton
            mxt
            onClick={() => setEditingAlbumName(!isEditingAlbumName)}
          >
            edit album name{" "}
          </StandardButton>
        </div>
      );
    } else {
      return getEditFormView();
    }
  }

  function NameBox() {
    const myInput = useInput(name, {
      validate: (newValue) => newValue.length < 150,
    });
    const save = (input) => {
      setAlbumName(input);
      storage.setAlbumName(input, album);
    };

    return (
      <GridContainer>
        <div className="grid">
          <input className="bg-black text-blue-400" {...myInput} />
          {/* <p>
          Value is <b>{myInput.value}</b>
        </p> */}
          <button
            onClick={() => {
              save(myInput.value);
              setEditingAlbumName(false);
            }}
          >
            save
          </button>
          <button
            onClick={() => {
              setEditingAlbumName(false);
            }}
          >
            cancel
          </button>
        </div>
        <DeleteAlbumButton album={album}></DeleteAlbumButton>
      </GridContainer>
    );
  }

  function getEditFormView() {
    const x = <NameBox></NameBox>;
    // const jcardImageUploadView = <

    const jcardImageUploadView = <JCardImageUploader album={album}></JCardImageUploader>;

    return (
      <div>
        {x}
        {/* {imageUploadView} */}
      </div>
    );
  }
  return <div>{getEditButton()}</div>;
}
