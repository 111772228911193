import React from 'react';

const MXTSplashPage = () => {
  return (
    <div className="w-full h-screen bg-lavender-200 px-6 sm:px-12 lg:px-24 py-12 text-white flex items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 max-w-7xl gap-8">
        <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left space-y-6 md:space-y-8">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold font-theFutureMonoRegular tracking-tighter" style={{ fontFamily: '"Test Söhne Mono", monospace' }}>
            .mixtape |
          </h2>
        </div>
        <div className="flex items-center text-center md:text-left">
          <p className="text-sm sm:text-base font-light leading-relaxed w-full sm:max-w-md" style={{ fontFamily: '"Test Söhne Mono", monospace' }}>
            diy music and shows
          </p>
        </div>
      </div>
    </div>
  );
};

export default MXTSplashPage;
