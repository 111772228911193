// util.js;
import imageCompression from "browser-image-compression";

function compressImage(file) {
  return new Promise((resolve, reject) => {
    imageCompression(file).then((compressed_file) => {
      resolve(compressed_file);
      // const output = URL.createObjectURL(output);
      // this.setState({
      //   compressedLink: downloadLink,
      // });
    });
  });
}

export const generateDefaultName = () => {
  const colors = ["Red", "Blue", "Green", "Yellow", "Purple", "Orange"];
  const animals = ["Cat", "Dog", "Fish", "Bird", "Turtle", "Horse"];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  const randomAnimal = animals[Math.floor(Math.random() * animals.length)];
  const randomNumber = Math.floor(Math.random() * 100);
  return `${randomColor}${randomAnimal}${randomNumber}`;
};


const utils = {
  compressImage,
};

export default utils;
