
// const baseServer = 'https://tuareg.appspot.com/' 
// const baseServer =  'https://mixtape-dev-dot-tuareg.appspot.com/';
const serverHost = 'http://127.0.0.1:8000/';
const baseServer = 'http://127.0.0.1:8000/';
// const serverHost = 'http://127.0.0.1:8000/';
// const baseServer = 'https://tuareg.app/';
// const serverHost = 'https://tuareg.app/';
const apiPath= 'api/v1/';
const config = {
    // baseURL: localHost,
    // baseurl: 'https://mixtape-dev-dot-tuareg.appspot.com/',
    baseurl: baseServer,
    api_uri: 'api/v1/',
    api: function(){
        // return 'https://mixtape-dev-dot-tuareg.appspot.com/api/v1/'
        return baseServer + apiPath
        // return localHost +  apiPath
    },
    server: function(){
        // return 'https://mixtape-dev-dot-tuareg.appspot.com/api/v1/'
        return serverHost
    },
    server2: function(){
        // return 'https://mixtape-dev-dot-tuareg.appspot.com/api/v1/'
        return serverHost + apiPath
    },
    jwt: function(){
        return `${this.api()}jwt/`;
    },
    refreshToken: function(){
        return `${this.api()}refresh-token/`;
    },
    register: function(){
	     return `${this.api()}auth/register/`;
    },
    feed: function() {
        return `${this.api()}feed/?limit=10?offset=1`;
    },
     mixtapes: function(){
        return `${this.api()}mixtapes/`;
    },
    fire: function(id) {
        return `${this.feed()}${id}/fire/`;
    },
    follow: function (id) {
        return `${this.api()}profiles/${id}/follow/`;
    },
    unfollow: function (id) {
        return `${this.api()}profiles/${id}/unfollow/`;
    },
    followers: function () {
        return `${this.api()}followers/`;
    },
    following: function () {
        return `${this.api()}following/`;
    },
    following: function () {
        return `${this.api()}tracks/`;
    },
    tracks: function () {
        return `${this.api()}tracks/`;
    },
    me: function () {
        return `${this.api()}profiles/me`;
    },

    profiles: function () {
        return `${this.api()}profiles/`;
    },

    buy: function () {
        return `${this.api()}profiles/buy`;
    },
    albums: function () {
        return `${this.api()}albums/`;
    },
    artist: function (id) {
      return `${this.api()}artists/${id}/`
    },
    artists_register: function () {
        return `${this.api()}artists/`
      },
    artists: function (id) {
      return `${this.api()}artists/${id}/`
    },
    individual_album: function (id) {
      return `${this.api()}albums/${id}/`
    },
    me: function (id) {
        return `${this.api()}profiles/me/`;
    },
    buy: function() {
      return `${this.api()}profiles/buy/`
    },
    upload: function() {
      return `${this.server2()}profiles/upload/`
    },
    upload_server: function() {
      return `${this.server2()}profiles/upload/`
    },
    img_upload: function() {
      return `${this.server2()}profiles/img_upload/`
    },
    link_label: function () {
        return `${this.api()}profiles/create_label/`
      },
    publish: function() {
        return `${this.server2()}profiles/publish/`
    },
    label_artists: function() {
        return `${this.api()}profiles/get_label_artists/`
    },
    add_artist_to_label: function() {
        return `${this.api()}profiles/add_new_artist/`
    },
};

export default config;
