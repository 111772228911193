import React, { useContext, useRef, useEffect } from "react";
import { PlayIcon, PauseIcon, SpeakerXMarkIcon, SpeakerWaveIcon } from "@heroicons/react/24/outline";
import AudioPlayerContext from "../../file_mgmt/drive/AudioPlayerContext";

const AudioPlayer = () => {
  const {
    src,
    playing,
    toggleAudio,
    setVolume,
    volume,
    currentTime,
    setCurrentTime,
    duration,
    setDuration, // Destructure setDuration from context
    setPlaying // Make sure to also destructure setPlaying if you're using it
  } = useContext(AudioPlayerContext);

  const audioRef = useRef(); // Reference to the audio element
  const isDraggingRef = useRef(false); // Reference to track dragging state
  const wasPlayingRef = useRef(playing); // Reference to track playing state during drag

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (audioRef.current) {
      const updateDuration = () => {
        setDuration(audioRef.current.duration);
      };
      audioRef.current.addEventListener('loadedmetadata', updateDuration);
      return () => {
        audioRef.current.removeEventListener('loadedmetadata', updateDuration);
      };
    }
  }, [src.audioSrc, setDuration]);

  const handleVolumeToggle = () => {
    setVolume(volume > 0 ? 0 : 1);
  };

  const handleTimeUpdate = () => {
    if (!isDraggingRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleMouseDown = () => {
    isDraggingRef.current = true;
    wasPlayingRef.current = playing;
    if (playing) {
      setPlaying(false);
      audioRef.current.pause();
    }
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
    if (wasPlayingRef.current) {
      setPlaying(true);
      audioRef.current.play();
    }
  };

  const handleProgressChange = (e) => {
    const newTime = e.target.value;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  // Format time from seconds to mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="audio-player fixed bottom-0 inset-x-0 z-60 bg-gh_charcoal_secondary-100 text-white p-4 rounded-t shadow-md">
      <audio ref={audioRef} onTimeUpdate={handleTimeUpdate} preload="metadata">
        <source src={src.audioSrc} />
      </audio>
      <div className="absolute top-0 left-0 right-0">
        <input
          type="range"
          min="0"
          max={duration ?? 100}
          value={currentTime}
          onChange={handleProgressChange}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          className="w-full cursor-pointer appearance-none bg-transparent h-1"
          style={{
            backgroundSize: `${(currentTime / (duration ?? 100)) * 100}% 100%`,
            backgroundImage: 'linear-gradient(to right, white 0%, white 100%)',
          }}
        />
      </div>
      <div className="audio-info flex justify-between items-center pt-8">
        <div>
          <span className="audio-title font-semibold text-lg block mb-1">
            {src.title}
          </span>
          <span className="audio-artist text-sm">{src.artist}</span>
        </div>
        <div className="audio-controls flex items-center">
          {playing ? (
            <PauseIcon
              className="control-icon h-8 w-8 cursor-pointer"
              onClick={toggleAudio}
            />
          ) : (
            <PlayIcon
              className="control-icon h-8 w-8 cursor-pointer"
              onClick={toggleAudio}
            />
          )}
          <span className="mx-2">{formatTime(currentTime)}</span>
          <div onClick={handleVolumeToggle}>
            {volume > 0 ? <SpeakerWaveIcon className="h-6 w-6" /> : <SpeakerXMarkIcon className="h-6 w-6" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
