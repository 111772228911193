// useDragDrop.js
// useDragDrop.js
import { useState } from 'react';
import drafting from '../../../../../services/drafting';

const useDragDrop = (data, album, setData, artistId = 0) => {
  const [homeIndex, setHomeIndex] = useState(null);

  const onDragStart = (start) => {
    const index = data.columnOrder.indexOf(start.source.droppableId);
    setHomeIndex(index);
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    // If there is no destination, return early
    if (!destination) return;

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    // Moving within the same list
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newState);
      // After state is updated, update the backend with the new track indices
      newTaskIds.forEach(async (taskId, index) => {
        const taskToUpdate = newState.tasks[taskId];
        taskToUpdate.index = index;
        await drafting.manageTrack(album, taskToUpdate, 'update');
      });

      await drafting.updateAlbumIndices(album);
    } else if (start.id === 'column-1' && finish.id === 'column-2') {
      // Handling file being dragged from the files list to the tracks list
      const newFinishTaskIds = Array.from(finish.taskIds);
      const fileBeingDragged = data.tasks[draggableId];

      // Create a new track object based on the file details
      const newTrack = {
        content: fileBeingDragged.content,
        trackName: fileBeingDragged.content, // or any other logic to determine track name
        compressed_filename: fileBeingDragged.compressed_filename,
        url: fileBeingDragged.url,
        index: destination.index,
        metadata: {
          artist_id: artistId, // Use the artistId from the hook
          featured_artists: [],
        },
        
      };

      // Add the new track to Firestore and get the new track ID
      const newTrackId = await drafting.manageTrack(album, newTrack, 'add');

      // Insert the new track ID at the dropped position
      newFinishTaskIds.splice(destination.index, 0, newTrackId);

      const newFinish = {
        ...finish,
        taskIds: newFinishTaskIds,
      };

      const newState = {
        ...data,
        tasks: {
          ...data.tasks,
          [newTrackId]: newTrack,
        },
        columns: {
          ...data.columns,
          [newFinish.id]: newFinish,
        },
      };

      setData(newState);

      // Update track indices for all tasks in the new finish column
      newFinishTaskIds.forEach(async (taskId, index) => {
        const taskToUpdate = newState.tasks[taskId];
        taskToUpdate.index = index;
        await drafting.manageTrack(album, taskToUpdate, 'update');
      });

      await drafting.updateAlbumIndices(album);
    }
    // ... handle other column types if needed
  };

  return { onDragStart, onDragEnd, homeIndex };
};


export default useDragDrop;