// MerchPanel.jsx


import React, { useEffect, useState } from "react";
import StandardButton from "../../buttons/StandardButton";
import GridContainer from "../../themes/GridContainer";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import orders from "../../../services/orders";
import merch from "../../../services/merch";
import auth from "../../../services/auth";

import { useHistory } from "react-router-dom";

let db = firebase.firestore();

const MerchPanel = () => {
    const [data, setData] = useState([]);
    const [_token, setToken] = useState("");
    const [loaded, setLoaded] = useState(false);
    let history = useHistory();

    function markAsShipped(docId) {

    }
    function showMerch() {
        data.array.forEach((element) => {
            return (
                <div>
                    <div>{data["name"]}</div>
                </div>
            );
        });
    }

    const merch_items_list = data.map((item) => (



        <div key={item.name} className="padded-5" >
            <button className="padded bordered"
                mxt_light onClick={() => {
                    history.push({
                        pathname: "/create/merch",
                        merchItem: item,
                    });
                }}>
                {item.name}
            </button>
        </div >
    ));

    async function getOrdersRef() {
        var promise = new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    firebase
                        .auth()
                        .currentUser.getIdToken(true)
                        .then(function (idToken) {
                            setToken(idToken);
                            resolve(user.uid);
                        });
                }
            });
        });

        return promise;
    }



    async function loadOrders() {
        setLoaded(true);
        const orders_ref = await auth.getUID().then(function (uid) {
            const unsubscribe = db
                .collection(`studio/${uid}/merch/`)
                .onSnapshot((snap) => {
                    const data = snap.docs.map((doc) => doc.data());
                    setData(data);
                });
            return () => unsubscribe();
        });
    }

    useEffect(() => {
        if (!loaded) {
            loadOrders();
        }
    }, [data]);

    function deleteMerchItem() {
        // inventory.deleteMerchItem(data.id);
    }

    const deleteBtn = (item) => {
        return (
            <StandardButton mxt onClick={deleteMerchItem(item.id)}>
                del
            </StandardButton>
        );
    };
    return (
        <GridContainer>
            <div >
                <h1 >merch drafts</h1>
                <div className="padded-5">
                    {merch_items_list}
                </div>
            </div>
        </GridContainer>
    );
};

export default MerchPanel;
