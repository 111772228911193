import {
  CustomCheckbox,
  CustomCheckboxContainer,
  CustomCheckboxInput,
} from "@reach/checkbox";
import React, { useContext, useState } from "react";
import { MixedCheckbox, useMixedCheckbox } from "@reach/checkbox";

import "@reach/checkbox/styles.css";
function Checkbox() {
  let [favoriteCondiments, setFavoriteCondiments] = React.useState({
    mayo: true,
    mustard: true,
    ketchup: false,
  });
  // We can determine if all or some of the nested checkboxes are selected and
  // use that to determine the state of our parent checkbox.
  let allCondimentsChecked = Object.keys(favoriteCondiments).every(
    (condiment) => favoriteCondiments[condiment] === true
  );
  let someCondimentsChecked = allCondimentsChecked
    ? false
    : Object.keys(favoriteCondiments).some(
        (condiment) => favoriteCondiments[condiment] === true
      );
  let parentIsChecked = allCondimentsChecked
    ? true
    : someCondimentsChecked
    ? "mixed"
    : false;
  // When we toggle a parent checkbox, we expect all of the nested checkboxes
  // to toggle with it.
  function handleParentChange(event) {
    setFavoriteCondiments(
      Object.keys(favoriteCondiments).reduce(
        (state, condiment) => ({
          ...state,
          [condiment]: !allCondimentsChecked,
        }),
        {}
      )
    );
  }
  function handleChildChange(event) {
    let { checked, value } = event.target;
    setFavoriteCondiments({
      ...favoriteCondiments,
      [value]: checked,
    });
  }
  return (
    <fieldset>
      <label>
        <MixedCheckbox
          value="condiments"
          checked={parentIsChecked}
          onChange={handleParentChange}
        />
        {allCondimentsChecked ? "Unselect" : "Select"} all condiments
      </label>
      <fieldset style={{ margin: "1rem 0 0", padding: "1rem 1.5rem" }}>
        <legend>Condiments</legend>
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          {Object.entries(favoriteCondiments).map(([value, state]) => (
            <li key={value}>
              <label>
                <MixedCheckbox
                  name="condiment"
                  value={value}
                  checked={state}
                  onChange={handleChildChange}
                />
                {value}
              </label>
            </li>
          ))}
        </ul>
      </fieldset>
    </fieldset>
  );
}
export default Checkbox;
