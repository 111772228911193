// FinalStep.js
import React from "react";

function FinalStep(props) {
  return (
    <div>
      <p>subscription title: {props.state.name}</p>
      <p>price: {props.state.price}</p>
      {/* <p>Email: {props.state.email}</p> */}
      {/* <p>Phone: {props.state.phone}</p> */}
      <button
        className=" p6 bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-md"
        onClick={props.prev}
      >
        Previous
      </button>
      <button
        className=" p6 bg-pasha hover:bg-white hover:shadow-outline hover:text-pasha hover:border hover:border-black focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-md"
        onClick={props.next}
      >
        Next
      </button>
    </div>
  );
}

export default FinalStep;
