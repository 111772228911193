// Main.jsx

import { Switch, Route } from "react-router-dom";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Signin from "./components/Signin";
import Home from "./components/Home";
import Settings from "./components/account/Settings";

import Dash from "./components/artist_dashboard/dashboard/Dash";
import Services from "./components/artist_dashboard/Services";

import StripeConnectRedirect from "./components/account/stripe/StripeConnectRedirect";
import ArtistPublicProfile from "./components/ArtistPublicProfile";
import Prices from "./components/subscriptions/Prices";
import Account from "./components/subscriptions/Account";
import StudioPlans from "./components/subscriptions/StudioPlans";
import ProductPage from "./components/artist_dashboard/dashboard/ProductPage";
import AccountView from "./components/subscriptions/AccountView";
import DraftEditView from "./components/artist_dashboard/dashboard/DraftEditView";
import MultiStepForm from "./components/artist_dashboard/dashboard/MultiStepForm";
import FinalizeDraft from "./components/artist_dashboard/dashboard/FinalizeDraft";
import FinalizeMaster from "./components/artist_dashboard/dashboard/FinalizeMaster";
import CreateProductView from "./components/artist_dashboard/dashboard/CreateProductView";
import PublishedMerchView from "./components/artist_dashboard/merch/meta_views/PublishedMerchView";
import OrdersView from "./components/inventory/OrdersView";
import StudioPlusUpgradeView from "./components/subscriptions/StudioPlusUpgradeView";
import CassetteDraftEditView from "./components/artist_dashboard/dashboard/CassetteDraftEditView";
import LabelView from "./components/label_dashboard/LabelView";
import Splash from "./services/Splash";
import LabelArtistView from "./components/label_dashboard/LabelArtistView";
import Otari from "./components/artist_dashboard/services/Otari";
import FBSigninView from "./auth/FBSigninView";
import EventView from "./components/events/EventView";
import CreateEventView from "./components/events/CreateEventView";
import CreateUsernameFromEventView from "./components/events/CreateUsernameFromEventView.jsx";
import ArtistShopView from "./components/ArtistShopView";
import NameEntryForm from "./components/events/NameEntryForm";
import PhazeSplash from "./services/PhazeSplash";
import Terminal from "./components/artist_dashboard/Terminal";
import AlbumsContainer from "./containers/AlbumsContainer";
import LabelCreationView from "./components/label_dashboard/LabelCreationView";
import MTRDriveView from "./components/artist_dashboard/drive/MTRDriveView";
import DriveView from "./components/file_mgmt/drive/DriveView";
import PublicFolderView from "./components/file_mgmt/drive/PublicFolderView";
import SharedFolderViewer from "./components/file_mgmt/drive/SharedFolderViewer";
import SignupView from "./auth/signup/SignupView";
import AudioMixer from "./components/artist_dashboard/drive/mtr_player/mixer/AudioMixer";
import SubscriptionCards from "./components/file_mgmt/drive/subscription/SubscriptionCards";
import JoinWaitlistForm from "./auth/onboarding/waitlist/JoinWaitlistForm";
import MemoriesPageView from "./components/events/memories/MemoriesPageView";
import ProfileView from "./components/events/profiles/ProfileView";
import APIConfig from "./APIConfig";
import PhazeSplashPage from "./components/artist_dashboard/drive/PhazeSplashPage.jsx";
import PhotoDetailView from "./components/events/memories/PhotoDetailView.jsx";
import Draft from "./components/artist_dashboard/drafting/Draft.jsx";
import ArtistRegistrationForm from "./components/forms/ArtistRegistrationForm.js";
import Konsole from "./components/artist_dashboard/Konsole.jsx";
import AlbumsComponent from "./components/mxt/music/AlbumsComponent.jsx";
import MXTSplashPage from "./components/artist_dashboard/drive/MXTSplashPage.jsx";
import SearchBar from "./components/search/SearchBar.jsx";
import ArtistSearch from "./components/search/ArtistSearch.jsx";
import LightSpinner from "./components/loading/LightSpinner.jsx";

const audioSources = [
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.5,
    pan: 0,
  },
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.75,
    pan: -0.5,
  },
  {
    url: "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3",
    volume: 0.25,
    pan: 0.5,
  },
];

const Main = (token) => {
  const [user, loading, error] = useAuthState(firebase.auth());

  if (loading) {
    return <div className="p-10"><LightSpinner/></div>; // Or any other loading indicator
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Handle the error as you see fit
  }

  return (
    <Switch>
      <Route path="/shared/:publicLinkId" component={PublicFolderView} />
      <Route exact path="/albums" component={AlbumsContainer} />
      <Route
        exact
        path="/home"
        render={(rProps) => (user ? <Home /> : <Signin />)}
      />
      <Route exact path="/subscribe" component={SubscriptionCards} />
      <Route path="/draft" component={Draft} />
      <Route
        path="/shared_folders/:publicLinkId"
        component={SharedFolderViewer}
      />
      <Route path="/waitlist" component={JoinWaitlistForm} />
      <Route
        path="/mixer"
        render={(rProps) => <AudioMixer audioSources={audioSources} />}
      />
      {/* label creator */}
      <Route exact path="/label" component={LabelCreationView} />
      <Route
        exact
        path="/"
        render={(rProps) => {
          if (APIConfig.phaze()) {
            return <PhazeSplashPage></PhazeSplashPage>;
          }
          if (APIConfig.studio()) {
            return user ? (
              <AlbumsComponent></AlbumsComponent>
            ) : (
              <MXTSplashPage></MXTSplashPage>
            );
          }
          return user ? <DriveView class="min-h-screen" /> : <Splash></Splash>;
        }}
      />


      <Route
        exact
        path="/home"
        render={(rProps) => (user ? <Home /> : <Signin />)}
      />

<Route
        exact
        path="/drive"
        render={(rProps) => (user ? <DriveView class="min-h-screen" /> : <Splash></Splash>)}
      />
      {/* <PlayerContainer /> */}
      <Route
        exact
        path="/orders"
        render={(rProps) =>
          token === null ? <Signin /> : <OrdersView></OrdersView>
        }
      />
      {/* artist registration */}
      <Route
        exact
        path="/artists/register"
        render={(rProps) => <ArtistRegistrationForm />}
      />
      <Route
        // /console
        exact
        path="/konsole"
        render={(rProps) => (token === null ? <Signin /> : <Konsole />)}
      />
      <Route exact path="/product" render={(rProps) => <ProductPage />} />
      <Route
        exact
        path="/create/album"
        render={(rProps) => (token === null ? <Signin /> : <DraftEditView />)}
      />
      <Route
        exact
        path="/create/cassette"
        render={(rProps) =>
          token === null ? <Signin /> : <CassetteDraftEditView />
        }
      />
      <Route exact path="/um" render={(rProps) => <PhazeSplash />} />
      <Route
        exact
        path="/inventory"
        render={(rProps) =>
          token === null ? <Signin /> : <PublishedMerchView />
        }
      />
      <Route
        exact
        path="/create/merch"
        render={(rProps) =>
          token === null ? <Signin /> : <CreateProductView></CreateProductView>
        }
      />
      <Route
        exact
        path="/view/artist"
        render={(rProps) =>
          token === null ? <Signin /> : <LabelArtistView></LabelArtistView>
        }
      />
      <Route
        exact
        path="/create/otari_project"
        render={(rProps) => (token === null ? <Signin /> : <Otari />)}
      />
      <Route
        exact
        path="/publish/album"
        render={(rProps) => (token === null ? <Signin /> : <FinalizeDraft />)}
      />
      <Route
        exact
        path="/analog/master"
        render={(rProps) => (token === null ? <Signin /> : <FinalizeMaster />)}
      />
      <Route
        path="/name_entry"
        render={(rProps) =>
          token === null ? (
            <NameEntryForm> </NameEntryForm>
          ) : (
            <NameEntryForm> </NameEntryForm>
          )
        }
      />
      <Route
        path="/a/:id"
        render={
          (rProps) => (token === null ? <ArtistShopView /> : <ArtistShopView />)
          // <ArtistShopView></ArtistShopView>
        }
      />
      <Route
        path="/search"
        render={(rProps) => (token === null ? <ArtistSearch /> : <ArtistSearch />)}
      />
      <Route
        path="/konsole"
        render={(rProps) => (token === null ? <Terminal /> : <Terminal />)}
      />
      <Route
        path="/a/:id"
        render={(rProps) =>
          token === null ? (
            <Signin />
          ) : (
            <ArtistPublicProfile></ArtistPublicProfile>
          )
        }
      />
      <Route
        exact
        path="/success"
        render={(rProps) =>
          token === null ? (
            <Signin />
          ) : (
            <StripeConnectRedirect></StripeConnectRedirect>
          )
        }
      />
      <Route
        exact
        path="/subscribe"
        render={(rProps) =>
          token === null ? (
            <Signin />
          ) : (
            <ArtistPublicProfile></ArtistPublicProfile>
          )
        }
      />
      <Route
        exact
        path="/plans"
        render={(rProps) =>
          token === null ? <Signin /> : <StudioPlans></StudioPlans>
        }
      />
      <Route
        exact
        path="/prices"
        render={(rProps) => (token === null ? <Signin /> : <Prices></Prices>)}
      />
      <Route
        exact
        path="/account"
        render={(rProps) => (token === null ? <Signin /> : <Account></Account>)}
      />
      <Route exact path="/splash" render={(rProps) => <Splash />} />
      <Route
        exact
        path="/account_mgmt"
        render={(rProps) =>
          token === null ? <Signin /> : <AccountView></AccountView>
        }
      />
      <Route
        exact
        path="/form"
        render={(rProps) =>
          token === null ? <Signin /> : <MultiStepForm></MultiStepForm>
        }
      />
      <Route
        exact
        path="/services"
        render={(rProps) =>
          token === null ? <Signin /> : <Services></Services>
        }
      />
      <Route path="/u/:id">
        <ProfileView />
      </Route>
      <Route
        exact
        path="/label"
        render={(rProps) =>
          token === null ? <Signin /> : <LabelView></LabelView>
        }
      />
      <Route
        exact
        path="/upload"
        render={(rProps) => (token === null ? <Signin /> : <Dash />)}
      />
      <Route
        exact
        path="/tapes/:id"
        render={(rProps) => (token === null ? <Signin /> : <MTRDriveView />)}
      />
      {/* 
            <Route
              exact
              path="/merch"
              render={(rProps) => (token === null ? <Signin /> : <Merch />)}
            /> */}
      <Route
        exact
        path="/settings"
        render={(rProps) => (token === null ? <Signin /> : <Settings />)}
      />
      <Route
        exact
        path="/upgrade"
        render={(rProps) =>
          token === null ? (
            <Signin />
          ) : (
            <StudioPlusUpgradeView></StudioPlusUpgradeView>
          )
        }
      />
      <Route exact path="/signup" component={SignupView} />
      <Route exact path="/signin" component={FBSigninView} />
      <Route path="/e/:eventId/memories" component={MemoriesPageView} />
      <Route
        path="/e/:id"
        render={(rProps) =>
          token === null ? <EventView></EventView> : <EventView></EventView>
        }
      />
      <Route path="/e/:id/photos/:photoId" component={PhotoDetailView} />{" "}
      {/* New route for individual photo details */}
      <Route
        path="/create"
        render={(rProps) =>
          token === null ? <CreateEventView /> : <CreateEventView />
        }
      />
      {/* create event route */}
      {/* /create_username_from_event */}
      <Route
        path="/create_username_from_event"
        render={(rProps) =>
          token === null ? (
            <CreateUsernameFromEventView></CreateUsernameFromEventView>
          ) : (
            <CreateUsernameFromEventView></CreateUsernameFromEventView>
          )
        }
      />
      {/* for /memories */}
      {/* <Route
                path="/memories"
                render={(rProps) =>
                  token === null ? (
                    <MemoriesView></MemoriesView>
                  ) : (
                    <MemoriesView></MemoriesView>
                  )
                }
              /> */}
    </Switch>
  );
};

export default Main;
