// User.js;
// @flow.
import React, {
  useState,
  useEffect,
  useParams,
  useContext,
  useRef,
} from "react";
import storage from "../../../services/storage";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import CreateAlbumView from "./album_creator/CreateAlbumView";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import StandardButton from "../../buttons/StandardButton";
import Uploader from "../../file_mgmt/uploader/Uploader";
import mastering from "../../../services/mastering";
import AudioPlayer from "../drive/AudioPlayer";
import Console from "../Console";
import DriveView from "../../file_mgmt/drive/DriveView";
import ToggleEditControlsView from "./album_creator/ToggleEditControlsView";
import drafting from "../../../services/drafting";
import AlbumDraftImageUploader from "./album_creator/views/images/AlbumDraftImageUploader";
import Sidebar from "../views/Sidebar";
import EditAlbumMetadataView from "./EditAlbumMetadataView";
import SetAlbumArtistView from "./album_creator/views/SetAlbumArtistView";
import ModularSidebar from "../views/ModularSidebar";
import WideButton from "../../buttons/WideButton";
import LightSpinner from "../../loading/LightSpinner";

function DraftEditView() {
  let location = useLocation();
  //(location);
  const [album, setAlbum] = useState({});
  const [tracks, setTracks] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingAudio, setLoadingAudio] = useState(true);
  let history = useHistory();
  const [showingUploader, showUploader] = useState(false);
  const [userUID, setUserUID] = useState("");
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [artistId, setArtistId] = useState(0);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  function uploadAudio() {
    if (showingUploader == true) {
      return (
        <div>
          <Uploader></Uploader>
          <StandardButton mxt_red onClick={() => showUploader(false)}>
            close
          </StandardButton>
        </div>
      );
    } else {
      return (
        <StandardButton mxt_green onClick={() => showUploader(true)}>
          upload audio
        </StandardButton>
      );
    }
  }
  async function finalize() {
    var _album = await storage.validateDraft(album);
    console.log("the album", _album);
    if (_album.isValid) {
      history.push({
        pathname: "/publish/album",
        album: _album,
      });
    } else {
      alert("finish creating the album first");
    }
  }

  async function loadDraft(docId) {
    var album_draft = await drafting.getDraft(docId);
    setArtistId(album_draft.artist_id); // Save the artist_id from the loaded draft
    var tracksArray = [];
    for (var track in album_draft.tracks) {
      const song = album_draft.tracks[track];
      const audioObj = {
        title: song.track_name,
        artist: album_draft.artist,
        audioSrc: song.url,
      };
      tracksArray.push(audioObj);
    }
    setTracks(tracksArray);
    setLoadingAudio(false);
    console.log("album_draft is: ", album_draft);
  }
  async function loadAlbum() {
    if (location.album == null) {
      var draft = await storage.getRecentDrafts();
      loadDraft(draft.docId);
      setAlbum(draft);
      setLoading(false);
    } else {
      //setting new album
      // console.log("loading draft2...", draft.docId);
      setAlbum(location.album);
      console.log("loading draft2...", location.album);
      loadDraft(location.album.docId);
      setLoading(false);
    }
  }
  useEffect(() => {
    loadAlbum();
  }, [location.album]);

  useEffect(() => {
    const checkIfLargeScreen = () => {
      setSidebarOpen(window.innerWidth >= 768);
    };

    checkIfLargeScreen();
    window.addEventListener("resize", checkIfLargeScreen);
    return () => window.removeEventListener("resize", checkIfLargeScreen);
  }, []);
  const SHOW_SIDEBAR = false; // Set to false to hide the sidebar

  return (
    <div className="flex h-screen py-5">
      {SHOW_SIDEBAR && (
        <ModularSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex-1 flex flex-col ${
          SHOW_SIDEBAR && isSidebarOpen ? "lg:ml-64" : ""
        }`}
      >
        {/* Rest of your content */}
        <main className="flex-grow p-4 overflow-auto">
          {isLoading ? (
            <div className="flex items-center justify-center p-10">
              <LightSpinner></LightSpinner>
            </div>
          ) : (
            <div className="container pb-16 mx-auto flex flex-col items-center justify-center">
              <AlbumDraftImageUploader album={album} />
              <EditAlbumMetadataView album={album} />
              <SetAlbumArtistView albumId={album?.docId} />
              <CreateAlbumView album={album} />
              <WideButton lambda={() => finalize()} text={"next"}></WideButton>
              {/* <StandardButton onClick={() => finalize()} className="mt-4">
                NEXT
              </StandardButton> */}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default DraftEditView;
