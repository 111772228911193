// Konsole.jsx
import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Link } from "react-router-dom";
import StandardButton from "../buttons/StandardButton";
import APIConfig from "../../APIConfig";
import storage from "../../services/storage";
import { useHistory } from "react-router-dom";
import profile_service from "../../services/profile_service";
import ArtistRegistrationForm from "../forms/ArtistRegistrationForm";
import GridContainer from "../themes/GridContainer";
import DraftListView from "./DraftListView";
import { useAuthState } from "react-firebase-hooks/auth";
import LoadingSkeleton from "./LoadingSkeleton";
import ArtistsList from "./dashboard/album_creator/views/ArtistsList";
import WideButton from "../buttons/WideButton";
import LinkedArtistsView from "./dashboard/album_creator/LinkedArtistsView";
import RotatingSlash from "../loading/RotatingSlash";
import { FaNutritionix } from "react-icons/fa";

const Konsole = (props) => {
  const [drafts, setDrafts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [artistName, setArtistName] = useState("");
  const [artists, setArtists] = useState([]); // State to store the list of artists
  const [username, setUsername] = useState(""); // State to store the username
  const [user, loading, error] = useAuthState(firebase.auth());

  const [_uid, setUID] = useState();
  const [hasStudio, setHasStudio] = useState(false);
  let history = useHistory();

  function welcome() {
    if (!username) {
      // return <RotatingSlash />;
      return null;
    }

    return (
      <div className="">
        <br />
        <div className="text-green-400 font-theFutureMonoRegular">
          welcome, @{username}
        </div>
        <br />
        {/* <ArtistsList artists={artists} /> */}
      </div>
    );
  }
  useEffect(() => {
    async function loadDrafts() {
      try {
        const _albums = await storage.getDrafts();
        setDrafts(_albums);
      } catch (error) {
        console.error("Error loading drafts:", error);
      }
    }

    if (user) {
      loadDrafts();
    }
  }, [user]);

  async function newAlbum(albumName) {
    var album = await storage.initializeAlbum(albumName);
    console.log("new album", album);
    history.push({
      pathname: "/create/album",
      album: album,
    });
  }

  function draftingPanel() {
    return (
      <div>
        <DraftListView drafts={drafts} /> {/* Use the new component */}
        {newAlbumButton()}
      </div>
    );
  }

  function newAlbumButton() {
    return (
      <WideButton
        text={"album+"}
        lambda={() => newAlbum(`untitled ${drafts.length}`)}
      />
    );
  }

  function loadView() {
    if (loading) {
      return <LoadingSkeleton />;
    } else if (error) {
      return <div>Error: {error.message}</div>;
    } else if (artistName === "no artist") {
      return <div>no artist linked, go to settings to register an artist</div>;
    } else {
      return welcome();
    }
  }

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          const idToken = await user.getIdToken(true);
          const res = await profile_service.getProfileInfo(idToken);
          const data = await res.json();

          setHasStudio(data["has_studio"]);
          setUID(user.uid);
          localStorage.setItem("uid", user.uid);

          if (data["artists"] && data["artists"].length > 0) {
            setArtists(data["artists"]);
            var artist_name = data["artists"].slice(-1).pop()["name"];
            setArtistName(artist_name);
            setUsername(data.username || "user");
          } else {
            setArtistName("no artist");
          }
        } catch (error) {
          console.error("Error fetching profile info:", error);
        }
      })();
    }
  }, [user]);

  return (
    <div className="py-2">
      {/* {loading && <div>Loading...</div>} */}
      {error && <div>Error: {error.message}</div>}
      {loadView()}
      <LinkedArtistsView />
      {draftingPanel()}
    </div>
  );
};

export default Konsole;
