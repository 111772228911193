import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useEventMemories from '../../file_mgmt/drive/hooks/useEventStorageHooks';
import SwipeableViews from 'react-swipeable-views'; // for swipe functionality
import { virtualize } from 'react-swipeable-views-utils';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const PhotoDetailView = () => {
  const { eventId, photoId } = useParams();
  const history = useHistory();
  const { memories } = useEventMemories(eventId);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    if (memories.length > 0) {
      const index = memories.findIndex(memory => memory.id === photoId);
      setPhotoIndex(index !== -1 ? index : 0);
    }
  }, [memories, photoId]);

  const handleChangeIndex = (index) => {
    const newPhotoId = memories[index].id;
    history.push(`/e/${eventId}/photos/${newPhotoId}`);
  };

  const slideRenderer = ({ key, index }) => {
    const memory = memories[index];
    return (
      <div key={key}>
        <img src={memory.downloadURL} alt={`Memory`} style={{ width: '100%', height: 'auto' }} />
      </div>
    );
  };

  return (
    <div>
      <VirtualizeSwipeableViews
        index={photoIndex}
        onChangeIndex={handleChangeIndex}
        slideRenderer={slideRenderer}
        slideCount={memories.length}
      />
    </div>
  );
};

export default PhotoDetailView;
