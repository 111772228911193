import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import React, { useContext, useState } from "react";
import Container from "../themes/Container";
function MenuTabs() {
  return (
    <Tabs>
      
      <TabPanels>
        <TabPanel>Uno</TabPanel>
        <TabPanel>Dos</TabPanel>
      </TabPanels>
      <TabList>
        <Tab>Uno</Tab>
        <Tab>Dos</Tab>
      </TabList>
    </Tabs>
  );
}
export default MenuTabs;
