// useEventStorageHooks.jsx
// useStorageHooks.js
import { useState, useContext, useCallback, useEffect } from "react";
import drive_service from "../../../../services/drive_service";
import { useAuthState } from "react-firebase-hooks/auth";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { getStorageRef } from "../../../../services/storage_service";
import PathContext from "../contexts/PathContext";

const auth = firebase.auth();
// export const useUploadFile = (items, setItems) => {
var db = firebase.firestore();


export const useUploadFile = (event) => {
  const [user] = useAuthState(auth);
  const { currentPath } = useContext(PathContext);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [error, setError] = useState(null);

  const uploadMemory = async (file) => {
    if (!user) return;
    setUploading(true);
  
    const fileName = `memory-${Date.now()}-${file.name}`;
    const filePath = `${currentPath}/${fileName}`;
    const storageRef = getStorageRef(user.uid, filePath);
  
    // Start the file upload
    const uploadTask = storageRef.put(file);
  
    // Monitor the upload progress
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // Update progress in the state
        setUploadProgress(prevProgress => {
          const existingProgress = prevProgress.find(p => p.id === fileName);
          if (existingProgress) {
            return prevProgress.map(p => p.id === fileName ? { ...p, progress } : p);
          } else {
            return [...prevProgress, { id: fileName, progress }];
          }
        });
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error("Error uploading file:", error);
        setError(error);
        setUploading(false);
        // Remove the progress bar in case of error
        setUploadProgress(prevProgress => prevProgress.filter(p => p.id !== fileName));
      },
      () => {
        // Handle successful uploads on complete
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          const newMemory = {
            downloadURL,
            eventId: event.id,
            mediaType: 'photo', // Assuming all files are photos, adjust if necessary
            name: fileName,
            storagePath: filePath,
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()), // This will create a Timestamp object
            type: 'image/jpg', // Adjust based on actual file type if necessary
            uploadedBy: {
              id: user.uid, // Assuming that the 'id' in the first record is the 'uid' from the user object
              uid: user.uid,
              username: user.displayName || user.email // Adjust to where the username is stored in your user object
            },
          };
  
          // Add the new memory to the Firestore
          db.collection("events").doc(event.id.toString()).collection("memories").add(newMemory)
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
  
          setUploading(false);
          // Remove the progress bar on complete
          setUploadProgress(prevProgress => prevProgress.filter(p => p.id !== fileName));
        });
      }
    );
  };
  

  return {
    uploadMemory,
    uploadProgress,
    uploading,
    error,
  };
};

























export const useDeleteFile = (items, setItems) => {
  const [user] = useAuthState(auth);

  const deleteFile = useCallback(
    async (fileId) => {
      if (!user) return;

      const driveRef = drive_service.getDriveRef(user.uid);
      const fileRef = driveRef.doc(fileId);
      const fileSnapshot = await fileRef.get();

      if (!fileSnapshot.exists) {
        console.error("File not found");
        return;
      }

      const fileData = fileSnapshot.data();
      const storageRef = getStorageRef(user.uid, fileData.storagePath);

      try {
        await storageRef.delete();
        await fileRef.delete();

        setItems(items.filter((item) => item.id !== fileId));
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    },
    [user, items, setItems]
  );

  return deleteFile;
};

export const useUserStorage = (userId) => {
  const [userStorage, setUserStorage] = useState(null);
  const userStorageRef = drive_service.getUserStorageRef(userId);

  useEffect(() => {
    if (!userId) {
      setUserStorage(null);
      return;
    }

    const unsubscribe = userStorageRef.onSnapshot((doc) => {
      if (doc.exists) {
        setUserStorage({ id: doc.id, ...doc.data() });
      } else {
        setUserStorage(null);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  return [userStorage];
};

const tagUsersInMemory = async (eventId, userIds) => {
  const firestore = firebase.firestore();

  // Get the event memory document
  const memoriesRef = firestore.collection("memories").doc(eventId);

  // Use transaction to ensure atomicity
  return firestore.runTransaction(async (transaction) => {
    const memoryDoc = await transaction.get(memoriesRef);

    // If the document doesn't exist, throw an error
    if (!memoryDoc.exists) {
      throw new Error(`Memory with id ${eventId} does not exist`);
    }

    // Otherwise, add the user ids to the usersInPhoto field
    const currentUsersInPhoto = memoryDoc.data().usersInPhoto || [];
    const newUsersInPhoto = [...new Set([...currentUsersInPhoto, ...userIds])];

    transaction.update(memoriesRef, { usersInPhoto: newUsersInPhoto });
  });
};


const useEventMemories = (eventId) => {
  const [memories, setMemories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Convert eventId to string if it's not
    const eventIdStr = typeof eventId === 'number' ? eventId.toString() : eventId;

    const memoriesRef = db.collection("events")
      .doc(eventIdStr)
      .collection("memories");

    // Set up a listener to receive updates when the data changes
    const unsubscribe = memoriesRef.onSnapshot(querySnapshot => {
      let memoryList = [];
      querySnapshot.forEach(doc => {
        memoryList.push({ id: doc.id, ...doc.data() });
      });

      setMemories(memoryList);
      setLoading(false);

    }, error => {
      console.error(`Error getting memories: ${error}`);
      setLoading(false);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [eventId]);

  return { memories, loading };
};

export default useEventMemories;