import React from "react";
import { useHistory } from "react-router-dom";

const DraftListView = ({ drafts }) => {
  let history = useHistory();

  const draftsList = drafts.map((draft) => (
    <li key={draft.id} className=" border-green-300">
      <button
        className="flex items-center justify-between w-full py-3 text-left text-gray-800 hover:bg-gray-700"
        onClick={() => {
          history.push({
            pathname: "/create/album",
            album: draft,
          });
        }}
      >
        <span className="ml-4 text-green-300 font-medium">{draft.name}</span>
        <span className="mr-4 text-sm text-green-600">edit</span>
      </button>
    </li>
  ));

  return (
    <div className="my-4">
      <h2 className="text-xl font-theFutureBold  text-green-300 pl-2 mb-3">drafts</h2>
      <ul className="bg-black font-mono ">
        {draftsList}
      </ul>
    </div>
  );
};

export default DraftListView;
