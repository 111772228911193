import React, { useState, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter } from "react-router-dom";
import api from "./api";
import NavBar from "./components/navigation/NavBar";
import firebase from "firebase/compat/app";
/* redux */
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./store/configureStore";
import Main from "./Main"
import APIConfig from "./APIConfig";
import { MultiTrackProvider } from "./components/file_mgmt/multi_uploader/MultiTrackContext";
import { AudioPlayerProvider } from "./components/file_mgmt/drive/AudioPlayerContext";
import AudioPlayerContainer from "./components/artist_dashboard/drive/AudioPlayerContainer";
import AudioPlayer from "./components/artist_dashboard/drive/AudioPlayer";
import { FileContextProvider } from "./components/file_mgmt/drive/contexts/fileContext";
import UploadContextProvider from "./components/file_mgmt/drive/contexts/UploadContextProvider";
import PathContextProvider from "./components/file_mgmt/drive/contexts/PathContextProvider";
import { ModalProvider } from "./components/file_mgmt/drive/contexts/ModalContext";
import { AuthProvider } from "./AuthContext";

const store = configureStore();
const stripePromise = api.getPublicStripeKey().then((key) => loadStripe(key));
export default function App() {
  const auth = firebase.auth();
  const [user] = useAuthState(auth); // Get the user state using the useAuthState hook
  const appStyle = "min-h-screen font-theFutureRegular text-gray-800 px-6 py-5 text-white dark:text-gh_charcoal_foreground_primary-100  bg-gh_charcoal-100 dark:bg-gh_charcoal-100 px-6 py-5 ring-1 ring-slate-900/5 shadow-xl"
  //  "min-h-screen font-theFutureRegular text-gray-800 px-6 py-5 text-white dark:text-gh_charcoal_foreground_primary-100  bg-gh_charcoal-100 dark:bg-gh_charcoal-100 px-6 py-5 ring-1 ring-slate-900/5 shadow-xl"
  // const appStyle = APIConfig.phaze()
    // ? "min-h-screen font-theFutureRegular text-gray-800 px-6 py-5 text-white dark:text-gh_charcoal_foreground_primary-100  bg-gh_charcoal-100 dark:bg-gh_charcoal-100 px-6 py-5 ring-1 ring-slate-900/5 shadow-xl"
    // : "min-h-screen font-theFutureRegular text-white dark:text-gh_charcoal_foreground_primary-100 bg-gh_white_secondary-100 dark:bg-gh_charcoal-100 px-6 py-5 ring-1 ring-slate-900/5 shadow-xl";
  return (
    <div>
      <div className={appStyle}>
        <AuthProvider>
          <Provider store={store}>
            <ModalProvider>
              <AudioPlayerProvider>
                <PathContextProvider>
                  <FileContextProvider>
                    <UploadContextProvider>
                      <MultiTrackProvider>
                        <BrowserRouter class="">
                          <div className="flex">
                            <div className="main-content flex-grow">
                              <NavBar class=""></NavBar>
                              <ToastContainer />
                              <Main class="" token={user ? user.uid : null} />
                              {/*  */}
                              {user && !APIConfig.phaze() && <AudioPlayer />}
                              {/* {user && <AudioPlayer />} */}
                            </div>
                          </div>
                        </BrowserRouter>
                      </MultiTrackProvider>
                    </UploadContextProvider>
                  </FileContextProvider>
                </PathContextProvider>
              </AudioPlayerProvider>
            </ModalProvider>
          </Provider>
        </AuthProvider>
      </div>
    </div>
  );
}
