// DeleteAlbumButton.js;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// import { useInput } from "./hooks/input-hook";
import storage from "../../../services/storage";
import StandardButton from "../../buttons/StandardButton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useHistory } from "react-router-dom";

export default function DeleteAlbumButton({ album }) {
  let history = useHistory();
  const submit = () => {
    confirmAlert({
      title: "confirm album draft deletion",
      message: "are you sure want to do this?",
      buttons: [
        {
          label: "yes",
          onClick: () => {
            storage.deleteAlbum(album);
            history.push({
              pathname: "/",
            });
          },
        },
        {
          label: "no",
          onClick: () => {},
        },
      ],
    });
  };
  function getEditButton() {
    return (
      <div>
        <button style={{ color: "red", margin: 20 }} onClick={() => submit()}>
          delete
        </button>
      </div>
    );
  }

  return <div>{getEditButton()}</div>;
}
